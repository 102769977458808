/**
 *  Markers.module.scss
 *
 *
 */

@import './_vars.scss';

.Markers {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 2rem;
}
.MarkersActive {
  z-index: 10;
}
