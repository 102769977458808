/**
 *  Timeline.module.scss
 *
 *
 */

@import './_vars.scss';

.Timeline {
  position: relative;
  width: 100%;
  height: 100%;
}
