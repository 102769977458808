/**
 *  ThemeFilter.module.scss
 *
 *
 */

@import './_vars.scss';
@import './_mixins.scss';

.ThemeFilter {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 25px 50px;
  pointer-events: none;
}
.ThemeFilterHeading {
  display: inline-block;
  margin-right: 5px;
  font-weight: 400;
  font-size: 1.3rem;
}