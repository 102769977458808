/**
 *  Thumb.module.scss
 *
 *
 */

@import './_vars.scss';
@import './_mixins.scss';

.Thumb {
  display: block;
  padding: 0;
  position: relative;
  width: 100%;
  pointer-events: auto;
}
.ThumbFigcaption {
  width: 40rem;
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  font-size: 1.5rem;
  color: $white;
  padding: 0;
  text-transform: none;
}
.ThumbImage {
  display: block;
  width: 100%;
  pointer-events: none;
}
.ThumbWrap {
  position: relative;
  opacity: 0;
  top: -275px;
  width: 400px;
  &.ThumbWrapActive {
    opacity: 1;
  }
}
.FigcaptionNote {
  // background-color: rgba(0, 0, 0, 0.5);
}
.FigcaptionTime {
  // background-color: $black;
  padding-right: 0.5rem;
}
.CloseButton {
  pointer-events: auto;
  display: block;
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  z-index: 3;
  top: 1rem;
  right: 1rem;
  border: solid 1px $white;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  font-size: 0;
  padding: 0;
}
.CloseButtonIcon {
  color: $white;
  position: absolute;
  display: block;
  font-size: 18px;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 2.2rem;
}
.CloseButtonLabel {
  @include sr-only();
}

