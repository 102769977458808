/**
 *  GridLine.scss
 *
 *
 */

@import './_vars.scss';

.GridLine {
  position: absolute;
  z-index: 1;
  width: 0;
  height: 100%;
  border-left: solid 1px $gray-9;
}
.GridLineLabel {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  white-space: nowrap;
  font-size: 1.5rem;
  padding-left: 0.5rem;
} 