/**
 *  StoryArc.module.scss
 *
 *
 */

@import './_vars.scss';

.StoryArc {
  position: absolute;
  z-index: 3;
  top: 20px;
  left: 0;
  width: 100%;
  height: 20px;
}
