@import './timeline/_vars.scss';

* {
  box-sizing: border-box;
}
html {
  width: 100%;
  height: 100%;
  font-size: 10px;
}
body {
  overflow: hidden;
  background-color: $black;
  margin: 0;
  padding: 0;
  font-family: $font-style-1;
  text-transform: uppercase;
  /*
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
  width: 100%;
  height: 100%;
}
code {
  font-family: $font-style-1;
}
#root {
  width: 100%;
  height: 100%;
}
figure,
figcaption {
  margin: 0;
  padding: 0;
}
figcaption {
}
button {
  font-family: $font-style-1;
  text-transform: uppercase;
  border: none;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
}
