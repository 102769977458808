/**
 *  EmotionMarker.module.scss
 *
 *
 */

@import './_vars.scss';
@import './_mixins.scss';

.EmotionMarker {
  font-size: 1.2rem;
  position: absolute;
  z-index: 1;
  top: auto;
  bottom: 0;
  width: 10px;
  height: 10px;
  overflow: visible;
}
.EmotionMarkerHovering .EmotionMarkerLabels {
  opacity: 1;
}
.EmotionMarkerHovering {
  z-index: 2;
}
.EmotionMarkerButtonWrap {
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  pointer-events: auto;
  color: $white;
  cursor: pointer;
  margin-left: -5px;
  margin-bottom: -5px;
  width: 10px;
  height: 10px;
}
.EmotionMarkerButton {
  width: 10px;
  height: 10px;
  background-color: $white;
  border-radius: 100%;
  padding: 4px 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
}
.EmotionMarkerLabels {
  opacity: 0;
  position: absolute;
  top: auto;
  bottom: 1rem;
  left: 0.5rem;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: left;
  border-left: solid 1px $white;
  padding-bottom: 1rem;
  pointer-events: none;
}
.EmotionMarkerLabel {
  display: inline-block;
  color: $gray-4;
  text-align: left;
  background-color: $black;
  float: left;
  clear: both;
  padding-left: 3px;
  padding-right: 3px;
  line-height: 1.5rem;
}
.EmotionMarkerLabelSelected {
  color: $white;
}
