/**
 *  GroupMarks.module.scss
 *
 *
 */

@import './_vars.scss';

$markWidth: 0.4;

.GroupMarks {
  position: absolute;
  z-index: 1;
  top: 2rem;
  left: 0;
  width: 100%;
  height: 100%;
  // display: none;
}
.GroupMark {
  float: left;
  width: 1rem;
  height: auto;
  position: absolute;
  top: 20rem;
  bottom: 20rem;
}
.GroupMarkLabel {
  visibility: hidden;
  display: block;
  transform: rotate(90deg);
  white-space: nowrap;
}
