/**
 *  Link.module.scss
 *
 *
 */

@import './_vars.scss';
@import './_mixins.scss';

$card-width: 200px;
$markerWidth: 0.4;
$markerHeight: $markerWidth;

.Link {
  display: block;
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  background-color: transparent;
}
.LinkExpanded {
  z-index: 99;
}
.LinkCard {
  font-size: 1.25rem;
  display: block;
  position: absolute;
  z-index: 2;
  top: 1.5rem;
  left: 0;
  width: 10px;
  height: 10px;
  margin-top: 0;
  margin-left: -5px;
  background-color: $gray-6;
  color: $black;
  padding: 0;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;
  text-align: left;
  pointer-events: auto;
  .LinkExpanded & {
    background-color: $white;
    width: $card-width;
    height: auto;
    border-radius: 0;
  }
}
.LinkCardImage {
  width: calc(100% - 1rem);
  margin: 0 0.5rem 0.25rem 0.5rem;
}
.LinkNote {
  display: block;
  pointer-events: none;
  opacity: 0;
  margin: 0.5rem;
  text-transform: none;
  .LinkExpanded & {
    opacity: 1;
  }
}
.LinkPointer {
  display: block;
  position: absolute;
  z-index: 1;
  width: 0;
  height: 2rem;
  border-left: dotted 1px $gray-6;
  top: 0.25rem;
  .LinkExpanded & {
    border-left: solid 1px $white;
  }
}
.LinkTime {
  display: block;
  width: $card-width;
  margin: 3rem 0.5rem 1rem 0.5rem;
  pointer-events: none;
  opacity: 0;
  .LinkExpanded & {
    opacity: 1;
  }
}
.CloseButton {
  pointer-events: auto;
  display: block;
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  z-index: 3;
  top: 0.5rem;
  right: 0.5rem;
  border: solid 1px $black;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  font-size: 0;
  padding: 0;
}
.CloseButtonIcon {
  color: $black;
  position: absolute;
  display: block;
  font-size: 18px;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 2.4rem;
}
.CloseButtonLabel {
  @include sr-only();
}
