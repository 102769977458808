/**
 *  Grid.module.scss
 *
 *
 */

@import './_vars.scss';

$gridHeight: 30;

.Grid {
  position: absolute;
  z-index: 3;
  top: -#{$gridHeight}px;
  left: 0;
  width: 100%;
  height: #{$gridHeight - 4}px;
  border-right: solid 1px $gray-9;
}
.GridMarkerLevel1 {
  border-left: solid 1px $white;
}
.GridMarkerLevel2 {
  border-left: solid 1px $gray-3;
}
.GridMarkerLevel3 {
  border-left: solid 1px $gray-1;
}
