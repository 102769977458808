/**
 *  _vars-color.scss
 *
 *
 */

$red:     #cc0000;
$white:   #fff;
$black:   #000;

$gray-1:  #111111;
$gray-2:  #252525;
$gray-3:  #393939;
$gray-4:  #4D4D4D;
$gray-5:  #616161;
$gray-6:  #757575;
$gray-7:  #898989;
$gray-8:  #9D9D9D;
$gray-9:  #B1B1B1;
$gray-10: #C5C5C5;
$gray-11: #D9D9D9;
$gray-12: #EEEEEE;
