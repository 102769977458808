/**
 *  EmotionCell.module.scss
 *
 *
 */

@import './_vars.scss';
@import './_mixins.scss';

.EmotionCell {
  float: left;
  border-top: dotted 1px $gray-4;
  width: 100%;
  height: percentage(1/4);
}
