/**
 *  Marker.module.scss
 *
 *
 */

@import './_vars.scss';
@import './_mixins.scss';

$markerWidth: 0.2;
$markerHeight: 2;

.Marker {
  float: left;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.MarkerActive .MarkerButton {
  // background-color: $red;
}
.MarkerButton {
  float: left;
  text-indent: -999px;
  overflow: hidden;
  display: block;
  position: absolute;
  top: 0;
  left: #{-($markerWidth / 2)}rem;
  width: #{$markerWidth}rem;
  height: #{$markerHeight}rem;
  padding: 0;
  border-left: solid 2px transparent;
  background-color: transparent;
  transition:
    height 0.15s ease-in-out,
    top 0.15s ease-in-out;
  .MarkerActive & {
    top: -#{$markerHeight * 3}rem;
    height: #{$markerHeight * 4}rem;
  }
}
.MarkerLabel {
  opacity: 0;
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 0 0.25rem 0 0;
  background-color: transparent;
  border: none;
  color: $white;
  transition: color 0.2s, opacity 0.2s;
  font-size: 1.5rem;
  text-align: right;
  .MarkerActive & {
    opacity: 1;
  }
}
