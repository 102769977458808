/**
 *  StoryArcMarker.module.scss
 *
 *
 */

@import './_vars.scss';

$marker-width: 15rem;

.StoryArcMarker {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: $marker-width;
  height: 20rem;
  border-left: dotted 1px $gray-8;
  color: $gray-8;
  pointer-events: none;
}
.StoryArcMarkerLabel {
  white-space: nowrap;
  font-size: 1.4rem;
  display: inline-block;
  background-color: transparent;
  color: $gray-8;
  position: absolute;
  z-index: 2;
  top: auto;
  bottom: 0;
  left: 0;
  padding: 0 0 0 0.35rem;
  cursor: pointer;
  pointer-events: auto;
  transition: color 0.2s;
  &:hover {
    color: $white;
  }
}
.StoryArcMarkerSelected {
  .StoryArcMarkerLabel {
    color: $white;
  }
}