/**
 *  ThemeFilterButton.module.scss
 *
 *
 */

@import './_vars.scss';
@import './_mixins.scss';

.ThemeFilterButton {
  padding-top: 4px;
  padding-bottom: 4px;
  border: solid 1px $white;
  background-color: transparent;
  color: $white;
  position: relative;
  margin-right: 4px;
  margin-bottom: 6px;
  pointer-events: auto;
  font-size: 1.3rem;
  &:active {
    top: 2px;
    left: 2px;
  }
  &:hover {
    background-color: $gray-6;
    color: $white;
  }
}
.ThemeFilterButtonSelected {
  border: solid 1px $white;
  background-color: $white;
  color: $black;
  top: 2px;
  left: 2px;
  &:hover {
    border: solid 1px $white;
    background-color: $white;
    color: $black;
  }
}