/**
 *  Links.module.scss
 *
 *
 */

@import './_vars.scss';

.Links {
  font-size: 1.5rem;
  color: $white;
  position: absolute;
  z-index: 9;
  top: 2rem;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
