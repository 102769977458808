/**
 *  Help.scss
 *
 *
 */

@import './_vars.scss';
@import './_mixins.scss';

.Help {
  pointer-events: none;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.HelpContent {
  transition: opacity 0.3s;
  opacity: 0;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.HelpContentVisible {
  opacity: 1;
  pointer-events: auto;
}
.HelpContent:before {
  content: " ";
  opacity: 0.75;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: $black;
  width: 100%;
  height: 100%;
}
.HelpContentBody {
  font-size: 1.5rem;
  max-width: 640px;
  line-height: 1.4;
}
.HelpContentInset {
  padding: 4rem;
}
.HelpContentTitle {
  font-size: 2rem;
}
.HelpToggle {
  pointer-events: auto;
  display: block;
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  z-index: 3;
  top: 3rem;
  right: 3rem;
  border: solid 1px $white;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  font-size: 0;
  padding: 0;
}
.HelpToggleIcon {
  color: $white;
  position: absolute;
  display: block;
  top: 2px;
  left: 8px;
  font-size: 16px;
  line-height: 2.0rem;
}
.HelpToggleIconClose {
  font-size: 18px;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 2.35rem;
}
.HelpToggleLabel {
  @include sr-only();
}
