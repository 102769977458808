/**
 *  EmotionPlot.module.scss
 *
 *
 */

@import './_vars.scss';
@import './_mixins.scss';

.EmotionPlot {
  position: absolute;
  z-index: 9;
  left: 0;
  top: 214px;
  width: 100%;
  height: 8rem;
  pointer-events: none;
}
.EmotionCells {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.EmotionMarkers {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
}
