/**
 *  App.module.scss
 *
 *
 */

@import './timeline/_vars.scss';

.App {
  color: $white;
  width: 100%;
  height: 100px;
  padding: 0 10rem 0 5rem;
  margin-top: 350px;
}
.AppBackground {
  opacity: 0.6;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-position: 50% 50%;
  background-size: cover;
}
